import React from "react"
import Layout from "../components/layout"
import {graphql, Link} from 'gatsby'
import Img from "gatsby-image"
import ShareButtons from "../components/share-buttons";
import './blog.css'
import SEO from "../components/seo";
import Helmet from "react-helmet";

const ImageGallery = ({images, alt}) => {
  return <div className="flex flex-col w-full blogimages my-4">
    {split(images, 3)
      .map((is, index) =>
        <div key={index} className="flex justify-center">
          {is.map(i => {
            return (
              <Img key={i.image.fluid.src} className="flex-1 self-auto m-1 w-full" fluid={i.image.fluid} alt={alt}/>);
          })}
        </div>)}
  </div>
}

const split = (array, chunk) => {
  let i, j, temparray;
  let r = []
  for (i = 0, j = array.length; i < j; i += chunk) {
    temparray = array.slice(i, i + chunk);
    r.push(temparray)
  }
  return r
}

const showSection = (c, title) => {
  switch (c.model.apiKey) {
    case 'textsection':
      return <div key={c.id}
                  className='blogsection w-full leading-normal py-4 font-sans font-light text-black text-xl'
                  dangerouslySetInnerHTML={{__html: c.text}}/>;
    case 'imagesection':
      return <Img key={c.id} className="mx-2 w-5/6 md:w-1/2" fluid={c.image.fluid} alt={title}/>;
    default:
      return <ImageGallery key={c.id} images={c.images.map(image => ({image}))} alt={title}/>
  }
}

export default ({location, data: {datoCmsBlog: b, allDatoCmsBlog: {edges: bs}}}) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage"
    },
    headline: b.title,
    image: [
      `${b.image.url}?ar=1:1&fit=crop`,
      `${b.image.url}?ar=4:3&fit=crop`,
      `${b.image.url}?ar=16:9&fit=crop`,
    ],
    genre: b.tags,
    datePublished: b.meta.publishedAt,
    dateModified: b.meta.updatedAt,
    author: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "LookStyler",
      url: "https://www.lookstyler.com",
      logo: {
        "@type": "ImageObject",
        url: "https://www.datocms-assets.com/10110/1552464165-lookstyler-secondary-mark-black.png"
      }
    },
    publisher: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "LookStyler",
      url: "https://www.lookstyler.com",
      logo: {
        "@type": "ImageObject",
        url: "https://www.datocms-assets.com/10110/1552464165-lookstyler-secondary-mark-black.png"
      },
    }
  }
  return (
    <Layout>
      <SEO seo={b.seoMetaTags} image={b.image.url}/>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div className="hidden md:flex items-start w-full pt-6 pb-2" style={{backgroundColor: '#eeeeee'}}>
        {bs
          .map(b => b.node)
          .map(b => <Link className='w-1/6 mx-6 no-underline flex flex-col' key={b.slug} to={'/blogs/' + b.slug + '/'}>
            <Img fluid={b.image.fluid} alt={b.title}/>
            <span className='font-sans font-light text-black py-2 text-sm'>{b.title}</span>
            <span className='font-sans text-red-700 '>{b.category}</span>
          </Link>)
        }
      </div>
      <section className="mt-4 flex flex-col justify-content items-center">
        <span className='font-sans text-red'>{b.category}</span>
        <h1 className='blogsection font-sans font-light text-black py-2 text-5xl'>{b.title}</h1>
        <section className="blogsection flex mt-4 justify-start w-full">
          <span className="font-title tracking-wide text-3xl mr-2">Share:</span>
          <ShareButtons url={location.href} media={b.image.url}/>
        </section>
        {b.content.map(c => showSection(c, b.title))}
      </section>
    </Layout>
  )
}

export const query = graphql`
    query GetBlog($slug: String!) {
        allDatoCmsBlog(sort: {fields: [id], order: DESC},limit:6) {
            edges {
                node {
                    title
                    slug
                    category
                    image{
                        fluid(maxWidth: 300, imgixParams: { fit: "crop", w: "300", h: "300" }) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }
        datoCmsBlog(slug: {eq: $slug}) {
            title
            category
            tags
            meta {
                publishedAt
                updatedAt
            }
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            image {
                url
            }
            content {
                ... on DatoCmsTextsection {
                    model {
                        apiKey
                    }
                    id
                    text
                }
                ... on DatoCmsImagesection {
                    model {
                        apiKey
                    }
                    id
                    image {
                        fluid(maxWidth: 600, imgixParams: { fit: "crop", w: "600" }) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
                ... on DatoCmsRawimagegallerysection {
                    model {
                        apiKey
                    }
                    id
                    images {
                        fluid(maxWidth: 600, imgixParams: { fit: "crop", w: "600" }) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }
    }
`