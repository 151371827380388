import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share';

const size = 32
const round = true

const ShareButtons = ({url, media}) => {
  return <div className="flex">
    <FacebookShareButton className='px-1' url={url}>
      <FacebookIcon size={size} round={round}/>
    </FacebookShareButton>
    <LinkedinShareButton className='px-1' url={url}>
      <LinkedinIcon size={size} round={round}/>
    </LinkedinShareButton>
    <TwitterShareButton className='px-1' url={url}>
      <TwitterIcon size={size} round={round}/>
    </TwitterShareButton>
    <PinterestShareButton className='px-1' url={url} media={media}>
      <PinterestIcon size={size} round={round}/>
    </PinterestShareButton>
    <WhatsappShareButton className='px-1' url={url}>
      <WhatsappIcon size={size} round={round}/>
    </WhatsappShareButton>
    <RedditShareButton className='px-1' url={url}>
      <RedditIcon size={size} round={round}/>
    </RedditShareButton>
  </div>;
}

export default ShareButtons